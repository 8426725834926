import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { setStatus, useState } from "react";

import CurrencyInput from "react-currency-input-field";
import { Layout } from "@components";
import Select from "react-select";
import formatNumber from "../../utils/formatNumber";
import { useFormik } from "formik";

const CREATE_PROGRAM_MUTATION = gql`
  mutation (
    $clientName: String!
    $clientDepartment: String
    $clientContactName: String
    $clientContactDetails: String
    $programName: String
    $programStatus: String
    $programCategory: String
    $programVenue: String
    $programPax: Int
    $programNoBatches: Int
    $programTypeIndoorModular: Float
    $programTypeTeambuilding: Float
    $programTypeConsultation: Float
    $programTypeIndoorConvention: Float
    $programEmtCost: Float
    $programToolName: String
    $programToolCost: Float
    $programToolPrice: Float
    $programToolQuantity: Int
    $programTeamProfileName: String
    $programTeamProfileCost: Float
    $programTeamProfilePrice: Float
    $programTeamProfileQuantity: Int
    $programOverhead: Float
    $programContractPrice: Float
  ) {
    createProgram(
      clientName: $clientName
      clientDepartment: $clientDepartment
      clientContactName: $clientContactName
      clientContactDetails: $clientContactDetails
      programName: $programName
      programStatus: $programStatus
      programCategory: $programCategory
      programVenue: $programVenue
      programPax: $programPax
      programNoBatches: $programNoBatches
      programTypeIndoorModular: $programTypeIndoorModular
      programTypeTeambuilding: $programTypeTeambuilding
      programTypeConsultation: $programTypeConsultation
      programTypeIndoorConvention: $programTypeIndoorConvention
      programEmtCost: $programEmtCost
      programToolName: $programToolName
      programToolCost: $programToolCost
      programToolPrice: $programToolPrice
      programToolQuantity: $programToolQuantity
      programTeamProfileName: $programTeamProfileName
      programTeamProfileCost: $programTeamProfileCost
      programTeamProfilePrice: $programTeamProfilePrice
      programTeamProfileQuantity: $programTeamProfileQuantity
      programOverhead: $programOverhead
      programContractPrice: $programContractPrice
    ) {
      program {
        id
        clientName
        clientDepartment
        programPax
        programNoBatches
        programStatus
        programCategory
        programTypeIndoorModular
        programTypeTeambuilding
        programTypeConsultation
        programTypeIndoorConvention
        programEmtCost
        programToolName
        programToolCost
        programToolPrice
        programToolQuantity
        programTeamProfileName
        programTeamProfileCost
        programTeamProfilePrice
        programTeamProfileQuantity
        programOverhead
        programContractPrice
      }
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  client_name: Yup.string().required("Required"),
  client_department: Yup.string(),
  client_contact_name: Yup.string().required("Required"),
  client_contact_details: Yup.string().required("Required"),
  program_name: Yup.string(),
  program_status: Yup.string(),
  program_venue: Yup.string(),
  program_pax: Yup.number()
    .typeError("Must be a number")
    .integer("Must be an integer")
    .min(1, "Must not be less than 1") // Ensures the value is 1 or above
    .required("Required"),
  program_no_batches: Yup.number()
    .typeError("Must be a number")
    .integer("Must be an integer")
    .min(1, "Must not be less than 1") // Ensures the value is 1 or above
    .required("Required"),
  program_type_indoor_modular: Yup.number()
    .typeError("Must be a number")
    .max(100, "Must be <= 100")
    .test(
      "total",
      "Total of program types must be equal 100",
      function (values) {
        const {
          program_type_indoor_modular,
          program_type_teambuilding,
          program_type_consultation,
          program_type_indoor_convention,
        } = this.parent;
        const total =
          (program_type_indoor_modular || 0) +
          (program_type_teambuilding || 0) +
          (program_type_consultation || 0) +
          (program_type_indoor_convention || 0);

        return total == 100;
      }
    ),
  program_type_teambuilding: Yup.number()
    .typeError("Must be a number")
    .max(100, "Must be <= 100")
    .test(
      "total",
      "Total of program types must be equal 100",
      function (values) {
        const {
          program_type_indoor_modular,
          program_type_teambuilding,
          program_type_consultation,
          program_type_indoor_convention,
        } = this.parent;
        const total =
          (program_type_indoor_modular || 0) +
          (program_type_teambuilding || 0) +
          (program_type_consultation || 0) +
          (program_type_indoor_convention || 0);

        return total == 100;
      }
    ),
  program_type_consultation: Yup.number()
    .typeError("Must be a number")
    .max(100, "Must be <= 100")
    .test(
      "total",
      "Total of program types must be equal 100",
      function (values) {
        const {
          program_type_indoor_modular,
          program_type_teambuilding,
          program_type_consultation,
          program_type_indoor_convention,
        } = this.parent;
        const total =
          (program_type_indoor_modular || 0) +
          (program_type_teambuilding || 0) +
          (program_type_consultation || 0) +
          (program_type_indoor_convention || 0);

        return total == 100;
      }
    ),
  program_type_indoor_convention: Yup.number()
    .typeError("Must be a number")
    .max(100, "Must be <= 100")
    .test(
      "total",
      "Total of program types must be equal 100",
      function (values) {
        const {
          program_type_indoor_modular,
          program_type_teambuilding,
          program_type_consultation,
          program_type_indoor_convention,
        } = this.parent;
        const total =
          (program_type_indoor_modular || 0) +
          (program_type_teambuilding || 0) +
          (program_type_consultation || 0) +
          (program_type_indoor_convention || 0);

        return total == 100;
      }
    ),
  program_emt_cost: Yup.number()
    .typeError("Must be a number")
    .max(100, "Value must not be greater than 100"),
  program_tool_name: Yup.string(),
  program_tool_cost: Yup.number().typeError("Must be a number"),
  program_tool_price: Yup.number().typeError("Must be a number"),
  program_tool_quantity: Yup.number().typeError("Must be an integer"),
  program_team_profile_name: Yup.string(),
  program_team_profile_cost: Yup.number().typeError("Must be a number"),
  program_team_profile_price: Yup.number().typeError("Must be a number"),
  program_team_profile_quantity: Yup.number().typeError("Must be an integer"),
  program_overhead: Yup.number()
    .typeError("Must be a number")
    .min(0.01, "Required") //Triggers the validation when nothing is selected (since 0 is less than 0.01)
    .required("Required"),
  program_contract_price: Yup.number().typeError("Must be a number"),
  program_category: Yup.string(),
});

export default function ProgramCreate() {
  const navigate = useNavigate();
  const [create_program] = useMutation(CREATE_PROGRAM_MUTATION, {
    onCompleted: (data) => {
      if (data && data.createProgram) {
        window.location.replace(
          `/programs/${data.createProgram.program.id}?message=Created successfully`
        );
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const [programToolNameId, setProgramToolNameId] = useState({
    label: "N/A",
    value: "",
  });

  const programToolNameOptions = [
    {
      label: "N/A",
      value: "",
    },
    {
      label: "CliftonStrengths",
      value: "CliftonStrengths",
    },
    {
      label: "HBDI",
      value: "HBDI",
    },
    {
      label: "NBI",
      value: "NBI",
    },
    {
      label: "Hogan",
      value: "Hogan",
    },
  ];

  const [programTeamProfileId, setProgramTeamProfileId] = useState({
    label: "N/A",
    value: "",
  });

  const programTeamProfileOptions = [
    {
      label: "N/A",
      value: "",
    },
    {
      label: "CliftonStrengths",
      value: "CliftonStrengths",
    },
    {
      label: "HBDI",
      value: "HBDI",
    },
    {
      label: "NBI",
      value: "NBI",
    },
  ];

  const [programOverheadId, setProgramOverheadId] = useState({
    label: "",
    value: "",
  });

  const programOverheadOptions = [
    {
      label: "31%",
      value: "31",
    },
    {
      label: "33%",
      value: "33",
    },
    {
      label: "35%",
      value: "35",
    },
  ];

  const formik = useFormik({
    initialValues: {
      client_name: "",
      client_department: "",
      client_contact_name: "",
      client_contact_details: "",
      program_name: "",
      program_status: "",
      program_venue: "",
      program_pax: 1,
      program_no_batches: 1,
      program_type_indoor_modular: 0.0,
      program_type_teambuilding: 0.0,
      program_type_consultation: 0.0,
      program_type_indoor_convention: 0.0,
      program_emt_cost: 0.0,
      program_tool_name: "",
      program_tool_cost: 0.0,
      program_tool_price: 0.0,
      program_tool_quantity: 0,
      program_team_profile_name: "",
      program_team_profile_cost: 0.0,
      program_team_profile_price: 0.0,
      program_team_profile_quantity: 0,
      program_overhead: 0.0,
      program_contract_price: 0.0,
      program_category: "",
    },
    validationSchema,
    onSubmit: (values) => {
      create_program({
        variables: {
          clientName: values.client_name,
          clientDepartment: values.client_department,
          clientContactName: values.client_contact_name,
          clientContactDetails: values.client_contact_details,
          programName: values.program_name,
          programStatus: values.program_status,
          programCategory: values.program_category,
          programVenue: values.program_venue,
          programPax: parseInt(values.program_pax, 10), // Convert to integer
          programNoBatches: parseInt(values.program_no_batches, 10), // Convert to integer
          programTypeIndoorModular: parseFloat(
            values.program_type_indoor_modular
          ),
          programTypeTeambuilding: parseFloat(values.program_type_teambuilding),
          programTypeConsultation: parseFloat(values.program_type_consultation),
          programTypeIndoorConvention: parseFloat(
            values.program_type_indoor_convention
          ),
          programEmtCost: parseFloat(values.program_emt_cost),
          programToolName: values.program_tool_name,
          programToolCost: parseFloat(values.program_tool_cost),
          programToolPrice: parseFloat(values.program_tool_price),
          programToolQuantity: parseInt(values.program_tool_quantity, 10), // Convert to integer
          programTeamProfileName: values.program_team_profile_name,
          programTeamProfileCost: parseFloat(values.program_team_profile_cost),
          programTeamProfilePrice: parseFloat(
            values.program_team_profile_price
          ),
          programTeamProfileQuantity: parseInt(
            values.program_team_profile_quantity,
            10
          ), // Convert to integer
          programOverhead: parseFloat(values.program_overhead),
          programContractPrice: parseFloat(values.program_contract_price),
        },
      });
    },
  });

  const [statusOptions, setStausOptions] = useState([
    { label: "Draft", value: "draft" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Active", value: "active" },
    { label: "Completed", value: "completed" },
  ]);

  const [status, setStatus] = useState({
    label: "Draft",
    value: "draft",
  });

  const [programCategoryOptions, setProgramCategoryOptions] = useState([
    { label: "NSTC", value: "NSTC" },
    { label: "UNPC", value: "UNPC" },
  ]);

  const [programCategory, setProgramCategory] = useState({
    label: "NSTC",
    value: "NSTC",
  });



  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-0">
              <div className="grid grid-cols-1 gap-x-8 border-b border-gray-900/10 pb-7 md:grid-cols-6">
                <div className="md:col-span-1 mt-5">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Client Information
                  </h2>
                  <p className="mt-1 text-xs leading-none text-gray-600">
                    Please fill in the details about the client.
                  </p>
                </div>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 sm:grid-cols-6 md:col-span-5">
                  <div className="col-span-full -my-2">
                    <div className="grid gap-x-6 gap-y-8 grid-cols-12 md:col-span-2">
                      <div className="sm:col-span-9">
                        <label
                          htmlFor="client_name"
                          className="label-on-input"
                        >
                          Name <span className="text-red-600">*</span>
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            autoFocus
                            id="client_name"
                            name="client_name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.client_name}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.client_name &&
                          formik.errors.client_name ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.client_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="program_overhead"
                          className="label-on-input z-10"
                        >
                          Overhead <span className="text-red-600">*</span>
                        </label>

                        <div className="mt-2 space-y-2">
                          <Select
                            options={programOverheadOptions}
                            name="program_overhead"
                            id="program_overhead"
                            instanceId="program_overhead"
                            value={{
                              label: programOverheadId.label,
                              value: programOverheadId.value,
                            }}
                            onChange={(selected) => {
                              formik.setFieldValue(
                                "program_overhead",
                                selected.value
                              );
                              setProgramOverheadId(selected);
                            }}
                            className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_overhead &&
                          formik.errors.program_overhead ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_overhead}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full -my-2">
                    <div className="grid gap-x-6 gap-y-8 grid-cols-12 md:col-span-2">
                      <div className="sm:col-span-5">
                        <label
                          htmlFor="client_department"
                          className="label-on-input"
                        >
                          Department{" "}
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="client_department"
                            name="client_department"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.client_department}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.client_department &&
                          formik.errors.client_department ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.client_department}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="client_contact_name"
                          className="label-on-input"
                        >
                          Contact Name{" "} <span className="text-red-600">*</span>
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="client_contact_name"
                            name="client_contact_name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.client_contact_name}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.client_contact_name &&
                          formik.errors.client_contact_name ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.client_contact_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="client_contact_details"
                          className="label-on-input"
                        >
                          Contact Info <span className="text-red-600">*</span>
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="client_contact_details"
                            name="client_contact_details"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.client_contact_details}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.client_contact_details &&
                          formik.errors.client_contact_details ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.client_contact_details}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-span-full -my-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                      <div className="sm:col-span-3">

                      </div>
                      <div className="sm:col-span-3">

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-8 border-b border-gray-900/10 pb-7 md:grid-cols-6">
                <div className="md:col-span-1 mt-5">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Program Details
                  </h2>
                  <p className="mt-1 text-xs leading-none text-gray-600">
                    Please fill in the details about the program.
                  </p>
                </div>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 sm:grid-cols-6 md:col-span-5">
                  <div className="col-span-full -my-2">
                    <div className="grid grid-cols-12 gap-x-6 gap-y-8 md:col-span-2">
                      <div className="sm:col-span-8">
                        <label
                          htmlFor="program_name"
                          className="label-on-input"
                        >
                          Program
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="program_name"
                            name="program_name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.program_name}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_name &&
                          formik.errors.program_name ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.program_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_category"
                          className="label-on-input z-10"
                        >
                          Category
                        </label>

                        <div className="mt-2 space-y-2">
                          <Select
                            options={programCategoryOptions}
                            name="program_category"
                            id="program_category"
                            instanceId="program_category"
                            value={{
                              label: programCategory.label,
                              value: programCategory.value,
                            }}
                            onChange={(selected) => {
                              formik.setFieldValue(
                                "program_category",
                                selected.value
                              );
                              setProgramCategory(selected);
                            }}
                            className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_category &&
                          formik.errors.program_category ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.program_category}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_status"
                          className="label-on-input z-[1] rounded-md"
                          style={{backgroundColor: "#6b7280", color: "white"}}
                        >
                          Status
                        </label>
                        <div className="mt-2 space-y-2">
                          <div className="bg-gray-500 text-white font-semibold block w-full rounded-md border-0 py-1.5 px-4 shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-center">
                            Draft
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full -my-2">
                    <div className="grid grid-cols-12 gap-x-6 gap-y-8 md:col-span-2">
                      <div className="sm:col-span-8">
                        <label
                          htmlFor="program_venue"
                          className="label-on-input"
                        >
                          Venue
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="program_venue"
                            name="program_venue"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.program_venue}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_venue &&
                          formik.errors.program_venue ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.program_venue}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_no_batches"
                          className="label-on-input"
                        >
                          Batches{" "} <span className="text-red-600">*</span>
                        </label>

                        <div className="mt-2 space-y-2">
                          <CurrencyInput
                            id="program_no_batches"
                            name="program_no_batches"
                            defaultValue={1}
                            value={formik.values.program_no_batches}
                            decimalsLimit={0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onValueChange={(value, name, values) =>
                              formik.setFieldValue("program_no_batches", value)
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_no_batches &&
                          formik.errors.program_no_batches ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_no_batches}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_pax"
                          className="label-on-input"
                        >
                          Pax{" "} <span className="text-red-600">*</span>
                        </label>

                        <div className="mt-2 space-y-2">
                          <CurrencyInput
                            id="program_pax"
                            name="program_pax"
                            defaultValue={1}
                            value={formik.values.program_pax}
                            decimalsLimit={0}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onValueChange={(value, name, values) =>
                              formik.setFieldValue("program_pax", value)
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_pax &&
                          formik.errors.program_pax ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_pax}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full -my-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 md:col-span-2">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="program_type_indoor_modular"
                          className="label-on-input"
                        >
                          Indoor Modular %
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="program_type_indoor_modular"
                            name="program_type_indoor_modular"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.program_type_indoor_modular}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_type_indoor_modular &&
                          formik.errors.program_type_indoor_modular ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_type_indoor_modular}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="program_type_teambuilding"
                          className="label-on-input"
                        >
                          Teambuilding %
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="program_type_teambuilding"
                            name="program_type_teambuilding"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.program_type_teambuilding}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_type_teambuilding &&
                          formik.errors.program_type_teambuilding ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_type_teambuilding}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="program_type_consultation"
                          className="label-on-input"
                        >
                          Consultation %
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="program_type_consultation"
                            name="program_type_consultation"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.program_type_consultation}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_type_consultation &&
                          formik.errors.program_type_consultation ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_type_consultation}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="program_type_indoor_convention"
                          className="label-on-input"
                        >
                          Indoor Convention %
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="program_type_indoor_convention"
                            name="program_type_indoor_convention"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.program_type_indoor_convention}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_type_indoor_convention &&
                          formik.errors.program_type_indoor_convention ? (
                            <div className="text-red-600 text-sm error-message">
                              {formik.errors.program_type_indoor_convention}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-span-full">
                    <label
                      htmlFor="program_emt_cost"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Program emt cost
                    </label>

                    <div className="mt-2 space-y-2">
                      <CurrencyInput
                        id="program_emt_cost"
                        name="program_emt_cost"
                        defaultValue={0.0}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) =>
                          formik.setFieldValue("program_emt_cost", value)
                        }
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formik.touched.program_emt_cost &&
                      formik.errors.program_emt_cost ? (
                        <div className="text-red-600 text-sm">
                          {formik.errors.program_emt_cost}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-8 border-b border-gray-900/10 pb-7 md:grid-cols-6">
                <div className="md:col-span-1 mt-5">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Tool & Team Profile
                  </h2>
                  <p className="mt-1 text-xs leading-none text-gray-600">
                    Fill in the details about the tool and team profile used in this program.
                  </p>
                </div>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 sm:grid-cols-6 md:col-span-5">
                  <div className="col-span-full -my-2">
                    <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-12 md:col-span-2">
                      <div className="sm:col-span-4">
                      <label
                        htmlFor="program_tool_name"
                        className="label-on-input z-[1]"
                      >
                        Tool
                      </label>

                      <div className="mt-2 space-y-2">
                      <Select
                          options={programToolNameOptions}
                          name="program_tool_name"
                          id="program_tool_name"
                          instanceId="program_tool_name"
                          value={{
                            label: programToolNameId.label,
                            value: programToolNameId.value,
                          }}
                          onChange={(selected) => {
                            formik.setFieldValue(
                              "program_tool_name",
                              selected.value
                            );
                            setProgramToolNameId(selected);
                          }}
                          className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                        {formik.touched.program_tool_name &&
                        formik.errors.program_tool_name ? (
                          <div className="text-red-600 text-sm">
                            {formik.errors.program_tool_name}
                          </div>
                        ) : null}
                      </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_tool_cost"
                          className="label-on-input"
                        >
                          Cost
                        </label>

                        <div className="mt-2 space-y-2">
                          <CurrencyInput
                            id="program_tool_cost"
                            name="program_tool_cost"
                            value={formik.values.program_tool_cost}
                            defaultValue={0.0}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) =>
                              formik.setFieldValue("program_tool_cost", value)
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_tool_cost &&
                          formik.errors.program_tool_cost ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.program_tool_cost}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_tool_price"
                          className="label-on-input"
                        >
                          Price
                        </label>

                        <div className="mt-2 space-y-2">
                          <CurrencyInput
                            id="program_tool_price"
                            name="program_tool_price"
                              value={formik.values.program_tool_price}
                            defaultValue={0.0}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) =>
                              formik.setFieldValue("program_tool_price", value)
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_tool_price &&
                          formik.errors.program_tool_price ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.program_tool_price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_tool_quantity"
                          className="label-on-input"
                        >
                          Quantity
                        </label>

                        <div className="mt-2 space-y-2">
                          <CurrencyInput
                            id="program_tool_quantity"
                            name="program_tool_quantity"
                            value={formik.values.program_tool_quantity}
                            defaultValue={0.0}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) =>
                              formik.setFieldValue(
                                "program_tool_quantity",
                                value
                              )
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.program_tool_quantity &&
                          formik.errors.program_tool_quantity ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.program_tool_quantity}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="program_tool_total"
                          className="label-on-input z-[1]"
                        >
                          Total
                        </label>
                        <div className="mt-2 space-y-2 text-right">
                          <div className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            {formatNumber(
                              formik.values.program_tool_quantity *
                                formik.values.program_tool_price
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full -my-2">
                      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 md:col-span-2">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="program_team_profile_name"
                            className="label-on-input z-[1]"
                          >
                            Team Profile
                          </label>

                          <div className="mt-2 space-y-2 -z-[1]">
                          <Select
                              options={programTeamProfileOptions}
                              name="program_team_profile_name"
                              id="program_team_profile_name"
                              instanceId="program_team_profile_name"
                              value={{
                                label: programTeamProfileId.label,
                                value: programTeamProfileId.value,
                              }}
                              onChange={(selected) => {
                                formik.setFieldValue(
                                  "program_team_profile_name",
                                  selected.value
                                );
                                setProgramTeamProfileId(selected);
                              }}
                              className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.program_team_profile_name &&
                            formik.errors.program_team_profile_name ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.program_team_profile_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="program_team_profile_cost"
                            className="label-on-input"
                          >
                            Cost
                          </label>

                          <div className="mt-2 space-y-2">
                            <CurrencyInput
                              id="program_team_profile_cost"
                              name="program_team_profile_cost"
                                value={formik.values.program_team_profile_cost}
                              defaultValue={0.0}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) =>
                                formik.setFieldValue(
                                  "program_team_profile_cost",
                                  value
                                )
                              }
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.program_team_profile_cost &&
                            formik.errors.program_team_profile_cost ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.program_team_profile_cost}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="program_team_profile_price"
                            className="label-on-input"
                          >
                            Price
                          </label>

                          <div className="mt-2 space-y-2">
                            <CurrencyInput
                              id="program_team_profile_price"
                              name="program_team_profile_price"
                                value={formik.values.program_team_profile_price}
                              defaultValue={0.0}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) =>
                                formik.setFieldValue(
                                  "program_team_profile_price",
                                  value
                                )
                              }
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.program_team_profile_price &&
                            formik.errors.program_team_profile_price ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.program_team_profile_price}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="program_team_profile_quantity"
                            className="label-on-input"
                          >
                            Quantity
                          </label>

                          <div className="mt-2 space-y-2">
                            <CurrencyInput
                              id="program_team_profile_quantity"
                              name="program_team_profile_quantity"
                                value={formik.values.program_team_profile_quantity}
                              defaultValue={0.0}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) =>
                                formik.setFieldValue(
                                  "program_team_profile_quantity",
                                  value
                                )
                              }
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.program_team_profile_quantity &&
                            formik.errors.program_team_profile_quantity ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.program_team_profile_quantity}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                         <label
                            htmlFor="program_team_profile_total"
                            className="label-on-input z-[1]"
                          >
                            Total
                          </label>
                          <div className="mt-2 space-y-2 text-right">
                            <div className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              {formatNumber(
                                formik.values.program_team_profile_quantity *
                                  formik.values.program_team_profile_price
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>

                </div>
              </div>

              {/*<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Program Financials
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Fill in the financial details related to the program.
                  </p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <div className="col-span-full -my-2">
                    <label
                      htmlFor="program_overhead"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Overhead <span className="text-red-600">*</span>
                    </label>

                    <div className="mt-2 space-y-2">
                      <Select
                        options={programOverheadOptions}
                        name="program_overhead"
                        id="program_overhead"
                        instanceId="program_overhead"
                        value={{
                          label: programOverheadId.label,
                          value: programOverheadId.value,
                        }}
                        onChange={(selected) => {
                          formik.setFieldValue(
                            "program_overhead",
                            selected.value
                          );
                          setProgramOverheadId(selected);
                        }}
                        className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formik.touched.program_overhead &&
                      formik.errors.program_overhead ? (
                        <div className="text-red-600 text-sm">
                          {formik.errors.program_overhead}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-span-full -my-2">
                    <label
                      htmlFor="program_contract_price"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contract Price
                    </label>

                    <div className="mt-2 space-y-2">
                      <CurrencyInput
                        id="program_contract_price"
                        name="program_contract_price"
                        defaultValue={0.0}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) =>
                          formik.setFieldValue("program_contract_price", value)
                        }
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formik.touched.program_contract_price &&
                      formik.errors.program_contract_price ? (
                        <div className="text-red-600 text-sm">
                          {formik.errors.program_contract_price}
                        </div>
                      ) : null}
                    </div>
                  </div>*/}

                  {/* <div className="col-span-full">
                    <p className="text-sm font-semibold text-gray-800">
                      Total contract price:{" "}
                      <span className="ml-1">{totalToolPrice(formik)}</span>
                    </p>
                  </div> */}

                  {/* <div className="col-span-full">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Status
                    </label>

                    <div className="mt-2 space-y-2">
                      <Select
                        options={statusOptions}
                        name="status"
                        id="status"
                        instanceId="status"
                        value={{
                          label: status.label,
                          value: status.value,
                        }}
                        onChange={(selected) => {
                          formik.setFieldValue(
                            "program_status",
                            selected.value
                          );
                          setStatus(selected);
                        }}
                        className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formik.touched.status && formik.errors.status ? (
                        <div className="text-red-600 text-sm">
                          {formik.errors.status}
                        </div>
                      ) : null}
                    </div>
                  </div> 
                </div>
              </div>*/}
            </div>

            <div className="mt-6 flex flex-row-reverse items-center justify-start gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
              <Link
                to={`/programs`}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );

  function totalToolPrice(formik) {
    let teamProfilePrice = parseFloat(
      formik.values.program_team_profile_quantity *
        formik.values.program_team_profile_price
    );
    let toolPrice = parseFloat(
      formik.values.program_tool_price * formik.values.program_tool_quantity
    );
    let management_price = parseFloat(formik.values.program_contract_price);

    return formatNumber(teamProfilePrice + toolPrice + management_price);
  }
}
