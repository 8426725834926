import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AdvanceAddMode from "./pages/Program/advance/new";
import AdvanceEdit from "./pages/Program/advance/edit";
import AdvanceEditMode from "./pages/Program/advance";
import Allowance from "./pages/Allowance";
import AllowanceAddMode from "./pages/Program/allowance/new";
import AllowanceEdit from "./pages/Program/allowance/edit";
import AllowanceEditMode from "./pages/Program/allowance";
import CashAdvance from "./pages/CashAdvance";
import InvoiceAdd from "./pages/Program/invoice/new";
import InvoiceEdit from "./pages/Program/invoice/edit";
import Invoices from "./pages/Invoices";
import LoginPage from "./pages/LoginPage";
import Personnel from "./pages/Personnel";
import PersonnelEdit from "./pages/Personnel/edit";
import PersonnelNew from "./pages/Personnel/new";
import Program from "./pages/Program";
import ProgramCreate from "./pages/Program/new";
import ProgramShow from "./pages/Program/show";
import ProgramUpdate from "./pages/Program/edit";
import React from "react";
import Report from "./pages/Report";
import RosterAddMode from "./pages/Program/roster/new";
import RosterEdit from "./pages/Program/roster/edit";
import RosterEditMode from "./pages/Program/roster";
import RosterPayables from "./pages/RosterPayables";
import SaleAddMode from "./pages/Program/sale/new";
import SaleComissionPayables from "./pages/SaleComissionPayables";
import SaleEdit from "./pages/Program/sale/edit";
import SaleEditMode from "./pages/Program/sale";
import Table from "./pages/Table";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<Program />} />
        <Route path="/programs" element={<Program />} />
        <Route path="/programs/:id" element={<ProgramShow />} />
        <Route path="/programs/:id/edit" element={<ProgramUpdate />} />
        <Route path="/programs/new" element={<ProgramCreate />} />
        <Route path="/programs/:id/sale-edit-mode" element={<SaleEditMode />} />
        <Route path="/programs/:id/sale-add" element={<SaleAddMode />} />
        <Route path="/programs/:id/sale/:salesId" element={<SaleEdit />} />
        <Route path="/programs/:id/roster-add" element={<RosterAddMode />} />
        <Route
          path="/programs/:id/roster-edit-mode"
          element={<RosterEditMode />}
        />
        <Route path="/programs/:id/roster/:rosterId" element={<RosterEdit />} />
        <Route
          path="/programs/:id/allowance-edit-mode"
          element={<AllowanceEditMode />}
        />
        <Route
          path="/programs/:id/allowance/:allowanceId"
          element={<AllowanceEdit />}
        />
        <Route
          path="/programs/:id/allowance-add"
          element={<AllowanceAddMode />}
        />
        <Route
          path="/programs/:id/advance-edit-mode"
          element={<AdvanceEditMode />}
        />
        <Route path="/programs/:id/advance-add" element={<AdvanceAddMode />} />
        <Route
          path="/programs/:id/advance/:advanceId"
          element={<AdvanceEdit />}
        />
        <Route path="/programs/:id/invoice-add" element={<InvoiceAdd />} />
        <Route
          path="/programs/:id/invoice/:invoiceId"
          element={<InvoiceEdit />}
        />

        <Route path="/table" element={<Table />} />

        <Route path="/cash-advance" element={<CashAdvance />} />

        <Route path="/personnel" element={<Personnel />} />
        <Route path="/personnel/new" element={<PersonnelNew />} />
        <Route path="/personnel/:id/edit" element={<PersonnelEdit />} />

        <Route path="/roster-payables" element={<RosterPayables />} />

        <Route path="/invoices" element={<Invoices />} />

        <Route
          path="/sale-comission-payables"
          element={<SaleComissionPayables />}
        />

        <Route path="/allowance" element={<Allowance />} />
        {/* Add as many Routes as you have different pages */}

        <Route path="/report" element={<Report />} />
      </Routes>
    </Router>
  );
}

export default App;
