import * as Yup from "yup";
import { useEffect } from "react";
import { Layout, ModalAlert } from "@components";
import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";

import CurrencyInput from "react-currency-input-field";
import Filter from "./filter";
import formatNumber from "../../utils/formatNumber";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useFormik } from "formik";
import { useState } from "react"; // Import useState hook

const ADMIN_ALLOWANCES_QUERY = gql`
  query (
    $endDate: String
    $page: Int
    $personnelId: String
    $program: String
    $startDate: String
    $status: String
  ) {
    adminAllowances(
      endDate: $endDate
      page: $page
      personnelId: $personnelId
      program: $program
      startDate: $startDate
      status: $status
    ) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        allowanceDate
        allowanceReference
        allowanceType {
          id
          referenceCode
          referenceDescription
          referenceType
        }
        cost
        expiryDate
        id
        insertedAt
        liquidatedCost
        program {
          id
          programName
        }
        personnel {
          firstName
          lastName
        }
        receiveReceiptDate
        status
        updatedAt
      }
    }
  }
`;

const EXPIRE_ALLOWANCE_MUTATION = gql`
  mutation ($id: String!) {
    expireAllowance(id: $id) {
      allowance {
        id
      }
    }
  }
`;

const LIQUIDATE_ALLOWANCE_MUTATION = gql`
  mutation (
    $id: String!
    $allowanceReference: String!
    $liquidatedCost: Float!
    $receiveReceiptDate: String!
  ) {
    liquidateAllowance(
      id: $id
      allowanceReference: $allowanceReference
      liquidatedCost: $liquidatedCost
      receiveReceiptDate: $receiveReceiptDate
    ) {
      allowance {
        id
      }
    }
  }
`;

// Update validation schema
const validationLiquidateSchema = Yup.object({
  allowanceReference: Yup.string().required("Allowance reference is required"),
  receiveReceiptDate: Yup.date().required("Receive receipt date is required"),
  liquidatedCost: Yup.number()
    .required("Liquidated cost is required")
    .positive("Liquidated cost must be a positive number"),
});

export default function Allowance() {
  const navigate = useNavigate();
  const [dataEntries, setDataEntries] = useState({});
  const [page, setPage] = useState(1);
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [showLiquidateModal, setShowLiquidateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [mutationLoading, setmutationLoading] = useState(false);
  const [selectedAllowance, setSelectedAllowance] = useState(null);

  const { loading, error, data, refetch } = useQuery(ADMIN_ALLOWANCES_QUERY, {
    variables: { page },
  });

  const [expire_allowance, { loading: mutationExpireLoading }] = useMutation(
    EXPIRE_ALLOWANCE_MUTATION,
    {
      onCompleted: (data) => {
        if (data && data.expireAllowance) {
          // Refetch the list and close the modal on success
          refetch().then(() => {
            setShowExpireModal(false); // Close the modal
          });
        } else {
          console.error("Error expiring the allowance");
        }
      },
      onError: (error) => {
        console.error("Error expiring the allowance:", error);
      },
    }
  );

  const [liquidateAllowance, { loading: mutationLiquidateLoading }] =
    useMutation(LIQUIDATE_ALLOWANCE_MUTATION, {
      onCompleted: () => {
        refetch();
        setShowLiquidateModal(false);
      },
      onError: (error) => {
        console.error("Error liquidating the allowance:", error);
      },
    });

  const formikLiquidate = useFormik({
    initialValues: {
      allowanceReference: "",
      receiveReceiptDate: "",
      liquidatedCost: "",
    },
    validationSchema: validationLiquidateSchema,
    onSubmit: (values) => {
      if (showLiquidateModal && selectedAllowance) {
        console.log({
          id: selectedAllowance.id,
          allowanceReference: values.allowanceReference, // Replace with actual reference
          liquidatedCost: parseFloat(values.liquidatedCost), // Convert amount to Float
          receiveReceiptDate: values.receiveReceiptDate,
        });
        liquidateAllowance({
          variables: {
            id: selectedAllowance.id,
            allowanceReference: values.allowanceReference, // Replace with actual reference
            liquidatedCost: parseFloat(values.liquidatedCost), // Convert amount to Float
            receiveReceiptDate: values.receiveReceiptDate,
          },
        });
      }
    },
  });

  // Update the selected allowance when the "Liquidate" button is clicked
  const handleLiquidateClick = (allowance) => {
    setSelectedAllowance(allowance); // Store the clicked allowance data
    setShowLiquidateModal(true); // Show the modal

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month

    const default_day = `${year}-${month}-${day}`;

    console.log(allowance.allowanceDate);
    // Set default form values using Formik's setValues
    formikLiquidate.setValues({
      allowanceReference: allowance.allowanceReference || "",
      receiveReceiptDate: default_day,
      liquidatedCost: allowance.cost || "",
    });
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    refetch({ page: e.selected + 1 });
  };

  const isExpire = (date) => {
    const dateString = date;

    if (dateString == null || dateString == undefined) {
      console.log("invalid date");
      return true;
    } else {
      // Parse the date string into a Date object
      const givenDate = new Date(dateString);
      // Add 30 days to the given date

      givenDate.setDate(givenDate.getDate() + 30);

      // Get today's date (only the date part, ignoring the time)
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to midnight for an accurate comparison

      // Check if today's date is equal to or after the date after adding 30 days
      if (today.getTime() > givenDate.getTime()) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setDataEntries(data);
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminAllowances.pageNumber
    ? (data.adminAllowances.pageNumber - 1) * data.adminAllowances.pageSize + 1
    : 0;
  const endOfResults = data.adminAllowances.totalEntries
    ? Math.min(
        startOfResults + data.adminAllowances.pageSize - 1,
        data.adminAllowances.totalEntries
      )
    : 0;

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Allowance
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the allowance
              </p>
            </div>
          </div>

          <div className="mt-4">
            <Filter filter={refetch} setPage={setPage} />
          </div>
        </div>

        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Date
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Program Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Personnel Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Cost
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Liquidated Cost
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataEntries?.adminAllowances?.entries.map((allowances) => (
                  <tr
                    id={allowances.id}
                    key={allowances.id}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                      {moment(allowances.allowanceDate).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {allowances.program.programName}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {allowances?.personnel?.firstName}{" "}
                      {allowances?.personnel?.lastName}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                      {allowances?.allowanceType?.referenceDescription}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatNumber(allowances.cost)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatNumber(allowances.liquidatedCost)}
                    </td>

                    <td>
                      {allowances.status == "active" ? (
                        <div className="flex flex-row space-x-3">
                          {isExpire(allowances.allowanceDate) && (
                            <button
                              onClick={() => {
                                setSelectedId(allowances.id);
                                setShowExpireModal(true);
                              }}
                              className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                              Expire
                            </button>
                          )}

                          <button
                            onClick={() => handleLiquidateClick(allowances)}
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Liquidate
                          </button>
                        </div>
                      ) : (
                        <div className="px-3 py-4 text-sm text-gray-500">
                          {allowances.status == "expired" &&
                            `Expired on ${moment(allowances.expiryDate).format(
                              "YYYY-MMM-DD"
                            )}`}
                          {allowances.status == "liquidated" &&
                            `Liquidated on ${moment(
                              allowances.receiveReceiptDate
                            ).format("YYYY-MMM-DD")}`}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="text-sm text-gray-700">
                  <p>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to{" "}
                    <span className="font-medium">{endOfResults}</span> of{" "}
                    <span className="font-medium">
                      {data.adminAllowances.totalEntries}
                    </span>{" "}
                    results
                  </p>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={data.adminAllowances.totalPages}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {showExpireModal && (
        <ModalAlert open={showExpireModal} setOpen={setShowExpireModal}>
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                class="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Confirm Allowance Expiration
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Are you sure you want to expire this allowance?
                </p>
              </div>
            </div>
          </div>

          {/* Modal actions */}
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => {
                if (selectedId) {
                  expire_allowance({
                    variables: {
                      id: selectedId,
                    },
                  });
                }
              }}
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              {mutationExpireLoading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3 ..."
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Yes"
              )}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={() => setShowExpireModal(false)}
            >
              No
            </button>
          </div>
        </ModalAlert>
      )}

      {/* Liquidate Modal */}
      {showLiquidateModal && (
        <ModalAlert open={showLiquidateModal} setOpen={setShowLiquidateModal}>
          <form onSubmit={formikLiquidate.handleSubmit}>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Liquidate Allowance
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Please enter the details for liquidating the allowance.
              </p>

              <div className="mt-4">
                <label
                  htmlFor="receiveReceiptDate"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Date
                </label>
                <input
                  id="receiveReceiptDate"
                  name="receiveReceiptDate"
                  type="date"
                  onChange={formikLiquidate.handleChange}
                  onBlur={formikLiquidate.handleBlur}
                  value={formikLiquidate.values.receiveReceiptDate}
                  max={currentDate}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabindex="5"
                />
                {formikLiquidate.touched.receiveReceiptDate &&
                  formikLiquidate.errors.receiveReceiptDate && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.receiveReceiptDate}
                    </div>
                  )}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="liquidatedCost"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Amount
                </label>
                <CurrencyInput
                  id="liquidatedCost"
                  name="liquidatedCost"
                  value={formikLiquidate.values.liquidatedCost}
                  decimalsLimit={2}
                  onValueChange={(value, name, values) =>
                    formikLiquidate.setFieldValue("liquidatedCost", value)
                  }
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabindex="1"
                />
                {formikLiquidate.touched.liquidatedCost &&
                  formikLiquidate.errors.liquidatedCost && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.liquidatedCost}
                    </div>
                  )}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="allowanceReference"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Reference
                </label>
                <input
                  id="allowanceReference"
                  name="allowanceReference"
                  type="text"
                  onChange={formikLiquidate.handleChange}
                  onBlur={formikLiquidate.handleBlur}
                  value={formikLiquidate.values.allowanceReference}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabindex="2"
                />
                {formikLiquidate.touched.allowanceReference &&
                  formikLiquidate.errors.allowanceReference && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.allowanceReference}
                    </div>
                  )}
              </div>

            </div>
            {/* Modal actions */}
            <div className="mt-5 pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                tabindex="3"
              >
                {mutationLiquidateLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 ..."
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                tabindex="4"
                onClick={() => setShowLiquidateModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </ModalAlert>
      )}
    </Layout>
  );
}
