import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { gql, useQuery } from "@apollo/client";

import { Layout } from "@components";
import React from "react";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend
);

const GET_ROSTER_RATE_SUMMARY_QUERY = gql`
  query {
    getRosterRateSummary {
      status
      rosterRateSummaryResult {
        id
        amount
        date
      }
    }
  }
`;

export default function Report() {
  // Fetch data from API
  const { data, loading, error } = useQuery(GET_ROSTER_RATE_SUMMARY_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  // Process data for charts
  const chartLabels = data.getRosterRateSummary.rosterRateSummaryResult.map(
    (item) => item.date
  );
  const chartData = data.getRosterRateSummary.rosterRateSummaryResult.map(
    (item) => item.amount
  );

  // Bar chart data
  const barData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Roster Rate Summary",
        data: chartData,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Doughnut chart data
  const doughnutData = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Reports
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the reports
              </p>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-12 gap-4">
            {/* Bar Chart */}
            <div className="col-span-7">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 pt-5 pb-2.5 sm:px-6">Bar Chart</div>
                <div className="px-4 pb-5 pt-2.5 sm:p-6">
                  <Bar data={barData} options={barOptions} />
                </div>
              </div>
            </div>

            {/* Doughnut Chart */}
            <div className="col-span-5">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 pt-5 pb-2.5 sm:px-6">Doughnut Chart</div>
                <div className="px-4 pb-5 pt-2.5 sm:p-6">
                  <Doughnut data={doughnutData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
